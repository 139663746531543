<template>
	<div class="lg:flex">
		<div class="flex flex-auto gap-20 pb-40 px-20 lg:px-40 lg:py-100">
			<div v-if="slots.content" class="flex-fill lg:w-300">
				<slot name="content" />
			</div>

			<div class="flex-auto">
				<v-slider-control
					color="primary"
					fill="solid"
					:slider="sliderEl"
					type="next" />

				<v-slider-control
					class="mt-20"
					color="primary"
					fill="outline"
					:slider="sliderEl"
					type="prev" />
			</div>
		</div>

		<div class="carousel">
			<v-slider
				ref="sliderEl"
				:options="{
					slidesPerView: 'auto',
					...options,
				}">
				<slot />
			</v-slider>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import Slider, { type SliderOptions } from './slider.vue'

	const props = defineProps<{
		options?: SliderOptions
	}>()

	const slots = useSlots()

	const sliderEl = ref<InstanceType<typeof Slider>>()
</script>

<style lang="scss">
	@layer components {
		.carousel {
			position: relative;
			flex: 1 0 0%;
			padding: 40px 20px 0 20px;
			overflow: hidden;
			background: linear-gradient(
				180deg,
				var(--carousel-bg-start, #{$gray-100}) 0%,
				var(--carousel-bg-end, transparent) 100%
			);

			@include media-breakpoint-up('lg') {
				padding: 100px 40px;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 50px;
				transform: translateY(-100%);
				z-index: 1;
				border-radius: 100%;
				box-shadow: 0 0px 20px 15px rgba(0, 0, 0, 0.16);

				@include media-breakpoint-up('lg') {
					top: 15px;
					left: 0;
					height: calc(100% - 30px);
					width: 50px;
					transform: translateX(-100%);
				}
			}

			.swiper {
				overflow: visible;
				z-index: 0;
			}
		}
	}
</style>
